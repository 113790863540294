import { Column } from "@ant-design/plots";
import { BASEURL, homeGetProFrequency, homeGetMsgServicePricing, smsSendStatistic } from "@api/index";
import { OpenConfirm } from "@components/Home/Product";
import { allDateRanges, PROTYPE_SMS, smsTypeOptions } from "@utils/enum";
import { productApi, setHref, toFixedNoRound, windowOpen } from "@utils/util";
import { Card, Col, Row, Select, DatePicker, Divider, Button } from "antd"
import React, { useEffect, useState } from "react"
import cardbg from '@assets/images/cardbg.png'
import { useSelector } from "react-redux";
import { StateType } from "@store/index";
import { handleDvmCommonListResult } from "@utils/request";
import { transformColumnChatData } from "../Statistics/cpnts/DataStatistics/cpnts/SendAnalysis";
import { useCommonApiHooks } from "@hooks/index";
import { handleCompactDateSubmitValue } from "@components/Common/Compact";

const getApi = productApi(smsSendStatistic)  // 查询的接口封装

const { RangePicker } = (DatePicker || {})
const mockData = [
    {
      name: 'London',
      月份: 'Jan.',
      月均降雨量: 18.9,
    },
    {
      name: 'London',
      月份: 'Feb.',
      月均降雨量: 28.8,
    },
    {
      name: 'London',
      月份: 'Mar.',
      月均降雨量: 39.3,
    },
    {
      name: 'London',
      月份: 'Apr.',
      月均降雨量: 81.4,
    },
    {
      name: 'London',
      月份: 'May',
      月均降雨量: 47,
    },
    {
      name: 'London',
      月份: 'Jun.',
      月均降雨量: 20.3,
    },
    {
      name: 'London',
      月份: 'Jul.',
      月均降雨量: 24,
    },
    {
      name: 'London',
      月份: 'Aug.',
      月均降雨量: 35.6,
    },
    {
      name: 'Berlin',
      月份: 'Jan.',
      月均降雨量: 12.4,
    },
    {
      name: 'Berlin',
      月份: 'Feb.',
      月均降雨量: 23.2,
    },
    {
      name: 'Berlin',
      月份: 'Mar.',
      月均降雨量: 34.5,
    },
    {
      name: 'Berlin',
      月份: 'Apr.',
      月均降雨量: 99.7,
    },
    {
      name: 'Berlin',
      月份: 'May',
      月均降雨量: 52.6,
    },
    {
      name: 'Berlin',
      月份: 'Jun.',
      月均降雨量: 35.5,
    },
    {
      name: 'Berlin',
      月份: 'Jul.',
      月均降雨量: 37.4,
    },
    {
      name: 'Berlin',
      月份: 'Aug.',
      月均降雨量: 42.4,
    },
]

const columnConfig = {
  xField: 'date',
  yField: 'num',
  isGroup: true,
  seriesField: 'name',
  color: ['#9AA6BC', '#FE9503', '#4FC87C','#0379FE',],
  label: {
    // 可手动配置 label 数据标签位置
    position: 'middle' as any,
    // 'top', 'bottom', 'middle',
    // 配置样式
    style: {
      fill: '#FFFFFF',
      opacity: 0.6,
    },
  },
  tooltip: {
    customContent: (title: any, items: any) => {
      console.log(222222222, items)
      const style = {marginLeft: 15, paddingBottom: 10}
      const { sendAmount, limitCount, phoneInvalidateCount, submitFailCount, unknownErrorCount } = items[0]?.data || {}
      return (
        <>
          <h5 style={{ paddingTop: 6 }}>{title}</h5>
          <ul style={{ paddingLeft: 0 }}>
            {items?.map((item: any, index: number) => {
              const { name, value, color, data } = item;
              return (
                <li
                  key={item.year}
                  className="g2-tooltip-list-item"
                  data-index={index}
                  style={{ marginBottom: 4, display: 'flex', alignItems: 'center' }}
                >
                  <span className="g2-tooltip-marker" style={{ backgroundColor: color }}></span>
                  <span
                    style={{ display: 'inline-flex', flex: 1, justifyContent: 'space-between' }}
                  >
                    <span style={{ marginRight: 16 }}>{name}:</span>
                    <span className="g2-tooltip-list-item-value">{value}条</span>
                  </span>
                </li>
              );
            })}
            <li  style={style}>计费数 :<span style={{marginLeft: '20px',float:'right'}} className="g2-tooltip-list-item-value">{items[0]?.data.chargeAmount}条</span></li>
          </ul>
        </>
      )
    }
  },
  xAxis: {
    label: {
      autoHide: true,
      autoRotate: false,
    },
  }
};

// 套餐包余量
const PkgRest = () => {
  const [data, , getApi] = useCommonApiHooks({url: `${BASEURL}/web/product/showQuotaTotal`}, {}, true)
  const getNum = (type: any) => data.find((item: any) => item.pmId === type)?.quota
  const hrefTo = () => setHref('/product/sms/statistics/pkgList')
  const Item = ({title, num, onClick}: any) => (<Col onClick={onClick} style={{width: '23%', height: 120, boxSizing: 'border-box', padding: 16, paddingTop: 24, borderRadius: 4, background: `url(${cardbg})`, backgroundSize: 'cover', cursor: 'pointer'}}>
                                          <div style={{marginBottom: 10}}>
                                            <Divider type="vertical" style={{height: 14, borderColor: '#0379FE'}} />{title}
                                          </div>
                                          <div style={{paddingLeft: 18}}>{(num || num === 0) ? <span style={{fontSize: 24, fontWeight: 400}}>{num}</span> : '暂未购买该套餐'}</div>
                                      </Col>)
  return (<Card title="套餐包余量" extra={<Button onClick={() => windowOpen('/shopping/purchase?piId=5')}>立即购买</Button>}>
      <Row justify="space-between">
          <Item title="验证码余量(条)" num={getNum(1)} onClick={hrefTo} />
          <Item title="短信通知余量(条)" num={getNum(2)} onClick={hrefTo} />
          <Item title="会员营销余量(条)" num={getNum(3)} onClick={hrefTo} />
          <Item title="推广短信余量(条)" num={getNum(4)} onClick={hrefTo} />
      </Row>
  </Card>)
}

// 发送上限设置
const SendUpLimitSetting = () => {
  const { ueSmsDayLimit, ueSingleSmsDayLimit } = useSelector((store: StateType) => store?.smsSetting) as any
  return (<Card title="发送上限设置" extra={<Button style={{}} onClick={() => windowOpen('/product/sms/applySetting/upLimit')}>设置上限</Button>}>
      <Row justify="space-around">
          <Col span={12}>
              <div style={{marginBottom: 10}}>每日发送上限</div>
              <div><strong style={{fontSize: 24}}>{ueSmsDayLimit}</strong> 条</div>
          </Col>
          <Col span={12}>
              <div style={{marginBottom: 10}}>每日单个手机号发送上限</div>
              <div><strong style={{fontSize: 24}}>{ueSingleSmsDayLimit}</strong> 条</div>
          </Col>
      </Row>
  </Card>)
}

// 按量扣费单价
const PriceCard = () => {
  const [open, setOpen] = useState(false)
  const [isOpen, setIsOpen] = useState(false)
  const [priceDate, setPriceData] = useState([])
  const getPriceApi = async () => {
    const { data } =  await homeGetMsgServicePricing({pageNum: 1, pageSize: 10, productModelType: 1, piId: 5}) as any
    const priceDate = data
    setPriceData(priceDate)
  }
  const getIsOpen = async () => {
    const { data } = (await homeGetProFrequency({ piId: 5 })) as any;
    setIsOpen(!!data);
  };
  const getTargetPrice = (type: any) => (priceDate as any).find((item: any) => item.pmId === type)?.userPrice
  useEffect(() => {
    getIsOpen()
    getPriceApi()
  }, [])
  return (<Card title="按量扣费单价" extra={ !isOpen ? <Button onClick={() => setOpen(true)}>开通按量付费</Button> :<div style={{height: '32px'}}></div> }>
      <Row justify="space-around" align="middle" style={{marginTop: 7}}>
          <Col>
              <div style={{marginBottom: 16}}>验证码</div>
              <div><strong style={{fontSize: 16}}>{getTargetPrice(1)}</strong> 元/条</div>
          </Col>
          <Divider type="vertical" style={{height: 60}} />
          <Col>
              <div style={{marginBottom: 16}}>短信通知</div>
              <div><strong style={{fontSize: 16}}>{getTargetPrice(2)}</strong> 元/条</div>
          </Col>
          <Divider type="vertical" style={{height: 60}} />
          <Col>
              <div style={{marginBottom: 16}}>会员营销</div>
              <div><strong style={{fontSize: 16}}>{getTargetPrice(3)}</strong> 元/条</div>
          </Col>
          <Divider type="vertical" style={{height: 60}} />
          <Col>
              <div style={{marginBottom: 16}}>推广短信</div>
              <div><strong style={{fontSize: 16}}>{getTargetPrice(4)}</strong> 元/条</div>
          </Col>
      </Row>
      <OpenConfirm
        open={open}
        piId={5}
        proType={PROTYPE_SMS}
        onCancel={() => setOpen(false)}
        onSuccess={() => {setOpen(false), getIsOpen()}}
      />
  </Card>)
}

// 发送量统计
const defaultValue = {msgType: undefined, date: allDateRanges['近30天']}
const sum = (data: any[], param: string) => toFixedNoRound((data||[]).map((v: any) => (v||{})[param]).reduce((a: number, b: number) => (a||0)*1 + (b||0)*1, 0))
const SendStatistic = () => {
  const [value, setvalue] = useState(defaultValue)
  const [dataSource, setDataSource] = useState([])
  const getChatApi = async (params = {}) => {
    const { data } = await getApi(params)
    const { values } = data || {}
    const { responses } = values || {}
    setDataSource(responses)
  }
  const Extra = ({value, onChange}: any) => {
      return (<>
          <Select
            value={value.msgType}
            placeholder="短信类型"
            options={smsTypeOptions}
            style={{minWidth: 120, marginRight: 24}}
            onChange={(msgType: any) => onChange && onChange({...value, msgType})}
          />
          <RangePicker
            value={value.date}
            allowClear={false}
            ranges={allDateRanges}
            onChange={(date: any) => onChange && onChange({...value, date})}
          />
      </>)
  }
  const Total = () => {
      return (<Row justify="space-around" style={{background: '#F3F4F6', borderRadius: 4, boxSizing: 'border-box', padding: 20}}>
          <Col span={6}>
              <div style={{color: 'rgba(0,0,0,0.65)', marginBottom: 10}}>
              <Divider type="vertical" style={{height: 14, borderColor: '#0379FE'}} />发送总数(条)
              </div>
              <div style={{paddingLeft: 18}}>{sum(dataSource, 'sendAmount')}</div>
          </Col>
          <Col span={6}>
              <div style={{color: 'rgba(0,0,0,0.65)', marginBottom: 10}}>
              <Divider type="vertical" style={{height: 14, borderColor: '#0379FE'}} />计费数(条)
              </div>
              <div style={{paddingLeft: 18}}>{sum(dataSource, 'chargeAmount')}</div>
          </Col>
          <Col span={6}>
              <div style={{color: 'rgba(0,0,0,0.65)', marginBottom: 10}}>
              <Divider type="vertical" style={{height: 14, borderColor: '#0379FE'}} />成功数(条)
              </div>
              <div style={{paddingLeft: 18}}>{sum(dataSource, 'successAmount')}</div>
          </Col>
          <Col span={6}>
              <div style={{color: 'rgba(0,0,0,0.65)', marginBottom: 10}}>
              <Divider type="vertical" style={{height: 14, borderColor: '#0379FE'}} />失败数(条)
              </div>
              <div style={{paddingLeft: 18}}>{sum(dataSource, 'failAmount')}</div>
          </Col>
      </Row>)
  }

  
// 计算滑块位置、范围
const countSlider = (list: any) => {
  const length = (list || []).length || 0
  const slider = {}
  if ( length <= 10) {
    return false
  } else if ( length <= 31) {
    Object.assign(slider, {
      start: 0,end: 0.6
    })
  } else {
    Object.assign(slider, {
      start: 0,end: 0.3
    })
  }
  return slider
}


  useEffect(() => {
    const { msgType, date } = value
    const startTime = date[0].format('YYYY-MM-DD')
    const endTime = date[1].format('YYYY-MM-DD')
    getChatApi({ timeType: 1, msgType, startTime, endTime })
  }, [value])
  return (<Card title="发送量统计" extra={<Extra value={value} onChange={setvalue} />}>
      <Total />
      <Column style={{marginTop: 20}} data={(transformColumnChatData(dataSource) || []).reverse()} slider={countSlider(dataSource)} {...columnConfig} />
  </Card>)
}
export default () => {
    return (<>
      <PkgRest />
      <Row justify="space-between" style={{margin: '20px 0'}}>
        <Col style={{paddingRight: '10px', width: '50%'}}>
          <SendUpLimitSetting />
        </Col>
        <Col style={{paddingLeft: '10px', width: '50%'}}>
          <PriceCard />
        </Col>
      </Row>
      <SendStatistic />
    </>)
}
