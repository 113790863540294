import { Button, Card, Tabs } from "antd"
import React, { useMemo, useRef, useState } from "react"
import ProTable, { ActionType } from "@ant-design/pro-table";
import { productApi } from "@utils/index";
import { homeSearch, homeTableAddUpdate, homeTableDel } from "@api/home";
import { columnsFn } from "./data";
import { ExportButton } from "@components/Common";
import { BASEURL, smsSendStatistic } from "@api/index";
import type { ModalFormProps, ProFormInstance } from '@ant-design/pro-form'
import { Column } from "@ant-design/plots";

// 接口请求的 api 层
const getApi = productApi(smsSendStatistic)  // 查询的接口封装
const addUpdateApi = productApi(homeTableAddUpdate) // 列表数据的新增/编辑 接口
const delApi = productApi(homeTableDel) // 表单数据的删除 接口

const mockData = [
    {
      name: 'London',
      月份: 'Jan.',
      月均降雨量: 18.9,
    },
    {
      name: 'London',
      月份: 'Feb.',
      月均降雨量: 28.8,
    },
    {
      name: 'London',
      月份: 'Mar.',
      月均降雨量: 39.3,
    },
    {
      name: 'London',
      月份: 'Apr.',
      月均降雨量: 81.4,
    },
    {
      name: 'London',
      月份: 'May',
      月均降雨量: 47,
    },
    {
      name: 'London',
      月份: 'Jun.',
      月均降雨量: 20.3,
    },
    {
      name: 'London',
      月份: 'Jul.',
      月均降雨量: 24,
    },
    {
      name: 'London',
      月份: 'Aug.',
      月均降雨量: 35.6,
    },
    {
      name: 'Berlin',
      月份: 'Jan.',
      月均降雨量: 12.4,
    },
    {
      name: 'Berlin',
      月份: 'Feb.',
      月均降雨量: 23.2,
    },
    {
      name: 'Berlin',
      月份: 'Mar.',
      月均降雨量: 34.5,
    },
    {
      name: 'Berlin',
      月份: 'Apr.',
      月均降雨量: 99.7,
    },
    {
      name: 'Berlin',
      月份: 'May',
      月均降雨量: 52.6,
    },
    {
      name: 'Berlin',
      月份: 'Jun.',
      月均降雨量: 35.5,
    },
    {
      name: 'Berlin',
      月份: 'Jul.',
      月均降雨量: 37.4,
    },
    {
      name: 'Berlin',
      月份: 'Aug.',
      月均降雨量: 42.4,
    },
]

const columnConfig = {
    xField: 'date',
    yField: 'num',
    isGroup: true,
    seriesField: 'name',
    color: [ '#9AA6BC', '#FE9503', '#4FC87C','#0379FE', ],
    label: {
      // 可手动配置 label 数据标签位置
      position: 'middle' as any,
      // 'top', 'bottom', 'middle',
      // 配置样式
      style: {
        fill: '#FFFFFF',
        opacity: 0.6,
      },
    },
    tooltip: {
      customContent: (title: any, items: any) => {
        const style = {marginLeft: 15, paddingBottom: 10}
        const { sendAmount, limitCount, phoneInvalidateCount, submitFailCount, unknownErrorCount } = items[0]?.data || {}
        return (
          <>
            <h5 style={{ paddingTop: 6 }}>{title}</h5>
            <ul style={{ paddingLeft: 0 }}>
              {items?.map((item: any, index: number) => {
                const { name, value, color, data } = item;
                return (
                  <li
                    key={item.year}
                    className="g2-tooltip-list-item"
                    data-index={index}
                    style={{ marginBottom: 4, display: 'flex', alignItems: 'center' }}
                  >
                    <span className="g2-tooltip-marker" style={{ backgroundColor: color }}></span>
                    <span
                      style={{ display: 'inline-flex', flex: 1, justifyContent: 'space-between' }}
                    >
                      <span style={{ marginRight: 16 }}>{name}:</span>
                      <span className="g2-tooltip-list-item-value">{value}条</span>
                    </span>
                  </li>
                );
              })}
              <li  style={style}>计费数 :<span style={{marginLeft: '20px',float:'right'}} className="g2-tooltip-list-item-value">{items[0]?.data.chargeAmount}条</span></li>
            </ul>
          </>
        )
      }
    },
    xAxis: {
      label: {
        autoHide: true,
        autoRotate: false,
      },
    }
};

export const transformColumnChatData = (data = []) => {
  const arr = (data||[]).map((v: any) => {
    const { 
      time: date, 
      sendAmount: total, 
      successAmount: successNum, 
      failAmount: failNum, 
      sendingAmount: sendingNum, 
      chargeAmount:chargeAmount,
      ...rest 
    } = v
    return [
      {
        date,
        name: '发送数量',
        num: total,
        chargeAmount,
        ...rest,
      },
      {
        date,
        name: '成功数',
        num: successNum,
        chargeAmount,
        ...rest,
      },
      // {
      //   date,
      //   name: '计费数',
      //   num: chargeAmount,
      //   ...rest,
      // },
      {
        date,
        name: '失败数',
        num: failNum,
        chargeAmount,
        ...rest
      },
      {
        date,
        name: '发送中',
        num: sendingNum,
        chargeAmount,
        ...rest
      }
    ]
  })
  return arr.flat()
}

// 计算滑块位置、范围
const countSlider = (list: any) => {
  const length = (list || []).length || 0
  const slider = {}
  if ( length <= 10) {
    return false
  } else if ( length <= 31) {
    Object.assign(slider, {
      start: 0,end: 0.6
    })
  } else {
    Object.assign(slider, {
      start: 0,end: 0.3
    })
  }
  return slider
}

const Chart = ({data}: any) => {
    return (<Card title={<>数据图表<span style={{color: '#686868', fontSize: 14, fontWeight: 'normal'}}>(最新数据更新时间: {data?.lastUpdateTime})</span></>} extra={null}>
        <Column data={(transformColumnChatData(data?.responses) || []).reverse()}  slider={countSlider(data?.responses)}  {...columnConfig} /> 
    </Card>)
  }

export default () => {
    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([])
    const [data, setDate] = useState([])
    const [modalProps, setModalProps] = useState({visible: false, title: '新增', params: {}})
    const formRef = useRef<ProFormInstance>()
    const ref = useRef<ActionType>(null); // ProTable 组件的 ref
    // operate 函数为增删改查需要处理的业务
    const operate = (type: any, record: any) => {
        const { id } = record || {}
    }

    const getListApi = async (params: any, sorter: any, filter: any) => {
        const { data } = await getApi({...params}, false)
        const { values, total } = data || {}
        const { responses } = values || {}
        setDate(values)
        return {
            data: responses || [],
            success: true,
            total
        }
    }

    const columns = columnsFn(operate) // 表格列配置
    // 刷新页面
    const reFresh = () => {
        // @ts-ignore
        ref.current?.clearSelected()
        ref.current?.reload()
    }
    const getQueryParams = () => ({
        // @ts-ignore
        ...formRef.current?.getFieldsFormatValue()
    })
    return (<ProTable<any>
        style={{flex: 1, overflow: 'scroll'}}
        scroll={{x: 1300}}
        columns={columns}
        formRef={formRef}
        params={{pageSize: 10000}} // 查询所需要的额外参数
        request={getListApi}
        options={{reload: false, density: false, setting: false}}
        toolbar={{
            actions: [
                <ExportButton key="export" type='primary' action={`${BASEURL}/web/dataStat/sendAmountStatExport`} param={getQueryParams} btnText="导出" />
            ],
        }}
        // rowKey="tiId"
        headerTitle={"发送量详情"}
        pagination={{defaultPageSize: 10, showSizeChanger: true, showQuickJumper: true}}
        actionRef={ref as any}
        tableExtraRender={() => useMemo(() => <Chart data={data} />, [data]) }
        search={{
            optionRender: (searchConfig, formProps, dom) => [ ...dom.reverse() ]
        }}
        // rowSelection={{
        //     preserveSelectedRowKeys: true,
        //     onChange: (selectedRowKeys: React.Key[], selectedRows: any[]) => setSelectedRowKeys(selectedRowKeys),
        // }}
    />)
}
