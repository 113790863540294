import React, { useEffect, useState } from "react"
import HomeHeader from "../../Layout/HomeHeader"
import { Button, Table, Checkbox, Popover, message } from 'antd';
import { proType, columns } from './data'
import {
    PlusOutlined,
    MinusOutlined,
} from '@ant-design/icons';
import './index.less'
import { divide } from "lodash";
import { ChargeModal } from "@components/CostCenter/Charge";
import { homeGetOrderInfo, homePostBuydoPay } from "@api/index";
import { handleDvmCommonListResult } from "@utils/request";
import { useHistory, useLocation } from "react-router-dom";
import { parseSearch,toFixedNoRound } from "@utils/util";
import { useSelector } from "react-redux";
import { StateType } from "@store/index";
// 商品清单
const DetailedList = ({ tableList, aciMoney,aciClouds, settlementData }: any) => {
    const { shouldPay, cloudPay, actualPay } = settlementData || {}
    return (<div className="">
        <div className="border-b bg-white p-3 px-8 text-lg">商品清单</div>
        <div className="bg-white p-8 leading-10 overflow-auto h-4/5">
            <div className="border-b">
                 <Table scroll={{ y: 200 }} columns={columns} pagination={false} dataSource={tableList} />
            </div>
            <div className="bg-gray-50 mt-6 px-4 py-2 flex flex-row-reverse">
                <div style={{color: '#686868'}}>
                    <div>商品总计： ￥{shouldPay}</div>
                    <div>云朵抵扣： ￥{cloudPay} （支付后云朵：<span className="text-red-500">{toFixedNoRound(aciClouds - cloudPay, 2)}</span>）</div>
                    <div>余额支付： ￥{toFixedNoRound(actualPay, 2)} （支付后余额：<span className="text-red-500">￥{toFixedNoRound(aciMoney -actualPay, 2)}</span>）</div>
                </div>
            </div>
        </div>
        
    </div>)
}

// 支付方式
const PayMethod = ({paidIn, aciMoney}: {paidIn: number, aciMoney: number}) => {
    const [chargeOpen, setChargeOpen] = useState(false)
  

    const chargeSuccess = () => {
        setChargeOpen(false)
    }
    return (<div className="mb-6">
        <div className="border-b bg-white p-3 px-8 text-lg">支付方式</div>
        <div className="bg-white p-8 leading-10 flex justify-between">
            <div>余额支付（<span style={{color: '#686868'}}>账户余额：</span><span className="jc-theme-color">￥{aciMoney}</span>）</div>
            <div className="flex">
                {aciMoney < paidIn ? <div><span className="text-gray-500">账户余额不足，</span></div> : null}
                {/* <ChargeModal trigger={<a>前去充值</a>} onSuccess={chargeSuccess} /> */}
            </div>
        </div>
        {/* 充值弹框 */}

    </div>)
}

// 结算台
const Settlement = ({ settlementData, oiId, aciMoney }: any) => {
    const history = useHistory()

    const { actualPay } = settlementData || {}
    const submit = async () => {
       const { msg, success } =  await homePostBuydoPay({orderId: oiId}) as any
       success && message.success('支付成功');
        success && setTimeout(() => {
            history.push('/overview')
        }, 1000);
            // history.push(`/home/product?piId=${piId}`)
    }

    
    // const Detailed = () => {
    //     return (<div>
    //         <div>商品总计： <span>￥{ priceTotal }</span></div>
    //         <div>云朵抵扣： <span>￥{cloundNumber}</span></div>
    //         <div>实付总计： <span>￥{paidIn}</span></div>
    //     </div>)
    // }
    const disabled = aciMoney < Math.floor(actualPay * 100) / 100 && Math.floor(actualPay * 100) / 100 !== 0
    return (<div className="bg-white items-center bottom-0 flex w-full justify-end p-4 settlement">
        <div className="mr-2">实付：</div>
        <div className="text-red-500 text-4xl font-semibold mr-4">
            ￥{Math.floor(actualPay * 100) / 100}
            {/* <div className="text-black mt-2 text-blue-500 font-normal rounded  text-xs text-center">
                <Popover content={Detailed} title="费用明细">
                    费用明细
                </Popover>

            </div> */}
        </div>
        <Button className='jc-theme-button' type="primary" disabled={disabled} size={'large'}  onClick={() => submit()}>{disabled ? '余额不足' : '确认支付'}</Button>
    </div>)
}
export default () => {
    const [settlementData, setSettlementData] = useState({
        shouldPay: 0,
        cloudPay: 0,
        actualPay:0,
        // readState: false,
    }) as any
    const { baseInfo: userBaseInfo } = useSelector((store: StateType) => store?.userInfo) as any
   
    const { aciMoney = 0, aciClouds = 0 } = (userBaseInfo || {}) as any
    const [tableList, setTableList] = useState([] as any)
    const { search } = useLocation()
    const { oiId } = parseSearch(search)


    const selectPrice = (shouldPay:number, type: number) => {
        // shouldPay   总价
        // aciMoney    账户余额
        // aciClouds   云朵余额
        let willClounds = 0
        let willMoney = 0
        if (aciClouds <= 0){
            willClounds = 0
            willMoney = shouldPay
        } else if (aciClouds >= shouldPay){
            willClounds = shouldPay
            willMoney = 0
        } else {
            willClounds = aciClouds
            willMoney = shouldPay - aciClouds
        }
        // priceChange(toFixedNoRound(aciMoney -selectPrice(2), 2))
        return   type === 1 ? willClounds : willMoney
    }


    const getData = async () => {
        const { result } = await homeGetOrderInfo({oiId}) as any
        const data = handleDvmCommonListResult(result, 'orderinfo')
        const basicinfo = handleDvmCommonListResult(result, 'basicinfo')
        const { shouldPay, cloudPay, actualPay } = basicinfo[0] || {} 
        setTableList(data)
        setSettlementData({
            shouldPay,
            cloudPay: selectPrice(shouldPay, 1),
            actualPay: selectPrice(shouldPay, 2),
        })
    }
    useEffect(() => {
        getData()
    }, [])



    return (<div className="home-orderSubmitDiv">
        <HomeHeader showMenu={false} />
        <div className="px-28 py-3 border shadow text-xl font-semibold" style={{paddingLeft: 24}}>订单确认</div>
        <div className="bg-gray-100 px-28 py-8  orderSubmitbody" style={{padding: 24}}>
            {/* 商品清单 */}
            <DetailedList tableList={tableList} aciMoney={aciMoney} aciClouds={aciClouds} settlementData={settlementData}/>
            {/* 支付方式 */}
            {/* <PayMethod aciMoney={aciMoney} paidIn={settlementData.paidIn}/> */}
        </div>
        {/* 结算组件 */}
        <Settlement oiId={oiId} aciMoney={aciMoney} settlementData={settlementData} />
    </div>)
}