import { BASEURL } from "@api/index"
import { useCommonApiHooks } from "@hooks/index"
import { allDateRanges, fromData } from "@utils/index"
import { Card } from "antd"
import React, { useEffect, useState } from "react"
import { Column } from '@ant-design/plots';
import { CompactDate, handleCompactDateSubmitValue, handleCompactDateValue } from "@components/Common/Compact"
import { ExportButton } from "@components/Common"
import { dateOptions } from "../SendAnalysis/data"

const mockData = [
    {
      name: 'London',
      月份: 'Jan.',
      月均降雨量: 18.9,
    },
    {
      name: 'London',
      月份: 'Feb.',
      月均降雨量: 28.8,
    },
    {
      name: 'London',
      月份: 'Mar.',
      月均降雨量: 39.3,
    },
    {
      name: 'London',
      月份: 'Apr.',
      月均降雨量: 81.4,
    },
    {
      name: 'London',
      月份: 'May',
      月均降雨量: 47,
    },
    {
      name: 'London',
      月份: 'Jun.',
      月均降雨量: 20.3,
    },
    {
      name: 'London',
      月份: 'Jul.',
      月均降雨量: 24,
    },
    {
      name: 'London',
      月份: 'Aug.',
      月均降雨量: 35.6,
    },
    {
      name: 'Berlin',
      月份: 'Jan.',
      月均降雨量: 12.4,
    },
    {
      name: 'Berlin',
      月份: 'Feb.',
      月均降雨量: 23.2,
    },
    {
      name: 'Berlin',
      月份: 'Mar.',
      月均降雨量: 34.5,
    },
    {
      name: 'Berlin',
      月份: 'Apr.',
      月均降雨量: 99.7,
    },
    {
      name: 'Berlin',
      月份: 'May',
      月均降雨量: 52.6,
    },
    {
      name: 'Berlin',
      月份: 'Jun.',
      月均降雨量: 35.5,
    },
    {
      name: 'Berlin',
      月份: 'Jul.',
      月均降雨量: 37.4,
    },
    {
      name: 'Berlin',
      月份: 'Aug.',
      月均降雨量: 42.4,
    },
]

const columnConfig = {
    xField: 'date',
    yField: 'rate',
    isGroup: true,
    isPercent: true,
    seriesField: 'name',
    label: {
      // 可手动配置 label 数据标签位置
      position: 'middle' as any,
      // 'top', 'bottom', 'middle',
      // 配置样式
      style: {
        fill: '#FFFFFF',
        opacity: 0.6,
      },
    },
    xAxis: {
      label: {
        autoHide: true,
        autoRotate: false,
      },
    },
    tooltip: {
      customContent: (title: any, items: any) => {
        const style = {marginLeft: 15, paddingBottom: 10}
        const { sendAmount, limitCount, phoneInvalidateCount, submitFailCount, unknownErrorCount } = items[0]?.data || {}
        return (
          <>
            <h5 style={{ paddingTop: 6 }}>{title}</h5>
            <ul style={{ paddingBottom: 5 }}>
              {items?.map((item: any, index: number) => {
                const { name, value, color, data } = item;
                return (
                  <li
                    key={item.year}
                    className="g2-tooltip-list-item"
                    data-index={index}
                    style={{ marginBottom: 4, display: 'flex', alignItems: 'center' }}
                  >
                    <span className="g2-tooltip-marker" style={{ backgroundColor: color }}></span>
                    <span
                      style={{ display: 'inline-flex', flex: 1, justifyContent: 'space-between' }}
                    >
                      <span style={{ marginRight: 16 }}>{name}:</span>
                      <span className="g2-tooltip-list-item-value">{value}</span>
                    </span>
                  </li>
                );
              })}
            </ul>
          </>
        )
      }
    },
};

const transformColumnChatData = (data = []) => {
  const arr = (data||[]).map((v: any) => {
    const { time: date, failRate, successRate: susRate } = v
    return [
      {
        date,
        name: '成功率',
        rate: (susRate||'').replace('%', '') * 1,
      },
      {
        date,
        name: '失败率',
        rate: (failRate||'').replace('%', '') * 1,
      },
    ]
  })
  return [...arr.flat()]
}

const defaultValue = {type: 'date', value: allDateRanges['近30天']}
const Extra = ({value, onChange, getParams}: any) => {
  return (<div style={{display: 'flex'}}>
      <CompactDate options={dateOptions} value={value} onChange={onChange} allowClear={false} />
      <ExportButton key="export" type='primary' action={`${BASEURL}/web/dataStat/arriveRateStatExport`} param={getParams} btnText="导出" />
  </div>)
}

export default () => {
  const [value, setValue] = useState<any>(defaultValue)
  const [data, , getApi] = useCommonApiHooks({url: `${BASEURL}/web/dataStat/arriveRateStat`, }, {}, false)
  const getParams = () => {
    return handleCompactDateSubmitValue(value)
  }
  useEffect(() => {
    const params = getParams()
    getApi(params)
  }, [value])
  const Title = () => <>数据图表<span style={{color: '#686868', fontSize: 14, fontWeight: 'normal'}}>(最新数据更新时间：{data?.values?.lastUpdateTime})</span></>
  return (<Card title={<Title />} extra={<Extra value={value} onChange={setValue} getParams={getParams} />}>
      <Column data={transformColumnChatData(data?.values?.responses)} {...columnConfig} /> 
  </Card>)
}
